
/*
    TIC-TAC-TOE
*/

.tictactoe-main-container {
    position: absolute;
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    background-color: aquamarine;

    touch-action: none;
    user-select: none;
}


.tictactoe-board {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 80vw;
    height: 80vw;
    max-width: 48dvh;
    max-height: 48dvh;

    background-color: transparent;

}

.cells {
    width: 29%;
    height: 29%;

    margin: 0;
    padding: 0;

    background-color: #121212;
    border: .25vmin solid white;

    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}

/*
    CELLS SYMBOL IMAGES (X AND O)
*/
.cells-image {
    width: 100%;
    height: 100%;
}

.hide-cells {
    opacity: 40%;
}

.highlight-cells {
    opacity: 100%;
}


.message {
    position: absolute;
    background-color: #121212b5;
    border: .1vmin solid white;
    border-radius: 1vmin;   

    -ms-interpolation-mode: nearest-neighbor;   
        image-rendering: -moz-crisp-edges;   
        image-rendering: pixelated;    
}

.message-won {
    top: 7.5%; 
    width: 75%;
    height: 17.5%;
}

.message-lost {
    top: 7.5%;
    width: 75%;
    height: 17.5%;
}

.message-draw {
    top: 5%;
    width: 50%;
    height: 18.75%;
}

.restart-button {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -160%);

    width: 90%;
    height: 16.5%;
    
    background-image: url('../../images/TicTacToe/play-again.png');
    background-size: 100%;
    background-color: #121212b5;
    background-repeat: no-repeat;

    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    
    border: .1vmin solid white;
    border-radius: 1vmin;

    cursor: pointer;
}
.restart-button:hover {
    background-color: rgb(24, 196, 196);
}


.levels-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;


    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -175%);

    width: 100%; 
    height: -webkit-max-content; 
    height: -moz-max-content; 
    height: max-content;

    max-width: 100dvh;

    margin: 0;
    padding: 0;
}


.levels {
    margin: 0;
    padding: 0;

    width: 30%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    border-radius: 1vmin;
    border: solid .1vmin black;

    cursor: pointer;
    opacity: .4;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
}


.levels:hover {
    opacity: 1;
}

.highlight {
    opacity: 1;
    cursor: default;
}

/*
    SCOREBOARD CONTAINER
*/

.scoreboard-container {
    position: absolute;
    top: 0; 
    left: 50%; 
    transform: translate(-50%, -105%);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    width: 110%;
    height: 18%;

    max-width: 90vw;

}


.score-info-container {
    position: relative;
    height: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    aspect-ratio: 2 / 1;

    display: flex;
    flex-direction: row;

    margin-left: 1%;
    margin-right: 1%;
  
}

.draws-info-container {
    aspect-ratio: 1 / 1;
}


.players-text-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);

    width: 100%;
    height: 50%;
    background-color: #121212c3;
    border: .05vmin solid white;
    border-radius: 1vmin;
}


.ttt-scoreboard-text {
    height: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
}

.draws-text-container {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, -100%);
    width: 100%;
    height: 50%;
    background-color: #121212c3;
    border: .05vmin solid white;
    border-radius: 1vmin;

}

/* PLAYERS AND AGENT SYMBOLS ON SCORBOARD */
.scoreboard-symbol {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}

/* CONTAINER FOR ACTUAL GAME SCORES */
.scoreboard-box {
    height: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    display: flex;
    flex-direction: row;
}


/* GAME SCORES IMAGES */
.ttt-scores {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;

    background-color: #121212;
    border: none;
    margin: 0;
    padding: 0;
}

@media (pointer: fine) {
    .cells {
        cursor: pointer;
    }
}