
/*
    HOMESCREEN TITLE
*/
.homescreen-main-container {
    position: absolute;
    left: 0; 
    top: 0; 
    width: 100%;
    height: 100%;

    background-color: transparent;
    touch-action: none;
}


.homescreen-title {
    position: absolute;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    -webkit-animation: hsTitleAnimation .5s ease;
            animation: hsTitleAnimation .5s ease;
}

/*
    TIC-TAC-TOE
*/
.ttt-homescreen-title {
    left: 50%;
    top: 25%;
    transform: translate(-50%, 0%);

    height: 14.5vw;
    max-height: 12vh;
}

/*
    MAZE
*/
.maze-homescreen-title {
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0%);

    height: 25vw;
    max-height: 15vh;
}

/*
    4COLORS
*/
.colors-homescreen-title {
    left: 50%;
    top: 25%;
    transform: translate(-50%, 0%);

    height: 15vw;
    max-height: 14vh;
}

/*
    MEMORY CARDS
*/
.memory-cards-homescreen-title {
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);

    height: 40vw;
    max-height: 27.5vh;
}

/*
    BLACKJACK
*/
.blackjack-homescreen-title {
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    height: 40vw;
    max-height: 25vh;

}


@-webkit-keyframes hsTitleAnimation {
    0% {
        opacity: 0;
        top: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes hsTitleAnimation {
    0% {
        opacity: 0;
        top: 0;
    }

    100% {
        opacity: 1;
    }
}

/*
    HOMESCREEN LOADING...
*/

.homescreen-loading-gif {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, 0%);

    height: 12.5vw;
    max-height: 8vh;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
}

/*
    HOMESCREEN BUTTON
*/

.homescreen-start-button {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, 0%);

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    margin: 0;
    padding: 0;
    box-sizing: none;

    border: .25vmin solid white;
    background-color: transparent;
    cursor: pointer;

    opacity: 0;
    -webkit-animation: hsStartButtonAnimation .75s ease .5s;
            animation: hsStartButtonAnimation .75s ease .5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.homescreen-start-button-image {
    height: 12.5vw;
    max-height: 8vh;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    margin: 0;
    padding: 0;
}

@-webkit-keyframes hsStartButtonAnimation {
    100% {
        opacity: 1;
    }
}

@keyframes hsStartButtonAnimation {
    100% {
        opacity: 1;
    }
}