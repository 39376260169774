

.blackjack-main-container {
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    background-color: rgb(18, 113, 18);

    touch-action: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


/*
    TABLE COMPONENT - DEALER'S HAND, PLAYER'S HAND, DECK OF CARDS
*/

.blackjack-table-container {
    position: relative;
    
    width: 100%;
    height: 84%;

    margin: 0;
    padding: 0;
    
    background-color: transparent;
}

/*
    TABLE MIDDLE CONTAINER: MESSAGES + USER INPUT BUTTONS 
*/
.blackjack-middle-container {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(0%, -50%);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 20%;

}

.place-your-bet {
    height: 6.5vw;
    max-height: 5vh;

    margin: 0;
    padding: 0;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    -webkit-animation: blinkAnimation .75s linear 0s infinite;

            animation: blinkAnimation .75s linear 0s infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

.blackjack-game-result {
    height: 15vw;
    max-height: 10vh;

    margin: 0;
    padding: 0;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
}

.blackjack-deal-button {
    width: 40vw;
    height: 20vw;

    max-width: 22vh;
    max-height: 11vh;

    margin: 0;
    padding: 0;

    border: none;
    background: none;

    background-image: url('../../images/Blackjack/deal-button.png');
    background-size: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    cursor: pointer;
}

.blackjack-deal-button:hover {
    background-image: url('../../images/Blackjack/deal-button-hover.png');
    background-size: 100%;
}

.blackjack-table-buttons {
    width: 20vw;
    height: 20vw;

    max-width: 11vh;
    max-height: 11vh;
    margin: 0 min(1vw, 1vh);
    padding: 0;

    border: none;
    border-style: none;
    background: none;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    cursor: pointer;
}

.blackjack-table-buttons:hover {
    transform: scale(1.075);
}

.blackjack-table-buttons img {
    height: 100%;
    width: 100%;
}


@-webkit-keyframes blinkAnimation {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}


@keyframes blinkAnimation {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}


/* BLACKJACK CARDS */
.blackjack-card {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    z-index: -1;
}

.blackjack-card-face {
    position: relative;
    width: 100%;
    height: 100%;

    transition: transform 0.5s;
    transform-style: preserve-3d;
    pointer-events: none;
}

.blackjack-card-front,
.blackjack-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
}

.blackjack-card-back {
    background-color: transparent;
}


.blackjack-card-front {
    background-color: transparent;
    transform: rotateY(180deg);
}

.blackjack-card-flip {
    transform: rotateY(180deg);
}



.dealers-hand {
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);

    width: 15vh;
    height: 20vh;

    border: .5vmin solid white;
}

.players-hand {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);

    width: 15vh;
    height: 20vh;

    border: .5vmin solid white;
}

.blackjack-losing-hand {
    opacity: 50%;
}

/*
    NUMBER COMPONENT: HAND COUNTS, BANKROLL, BET COUNT
*/
.blackjack-hand-count {
    position: absolute;
    left: 50%;
    top: 82.5%;
    transform: translate(-50%, -50%);

    margin: 0;
    padding: 0;

    display: flex;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    height: 17.5%;

    background-color: #332f2f;
}

.blackjack-number {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    margin: 0;
    padding: 0;
}

.players-bet {
    position: absolute;
    left: 50%;
    top: 110%;
    transform: translate(-50%, 0%);

    width: 40%;
    height: 30%;

    border: .1vmin solid white;

}

.players-bet-chips {
    position: absolute;
    left: 0%; 
    top: 0;

    width: 100%;
    height: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
}

.blackjack-bet-amount {
    background-color: #121212;
    
    position: absolute;
    left: 110%;
    top: 0;

    padding: 0;
    margin: 0;
    
    width: -webkit-max-content;
    
    width: -moz-max-content;
    
    width: max-content;
    height: 60%;

}

/*
    BANKROLL COMPONENT - TOTAL MONEY, CHIPS, PLACING BETS
*/
.bankroll-container {
    position: absolute;
    left: 0; 
    top: 100%;
    transform: translate(0%, -100%);

    width: 100%;
    height: 8%;
    display: flex;
    justify-content: center;

    background-color: #5c4033;

}

.blackjack-bankroll-money {
    height: 80%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: auto;
    
}

.bankroll-chips-container {
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -100%);

    height: 100%;
    width: 17.5%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.bankroll-chips {
    height: 100%;

    margin: auto min(.3vw, .2vh);

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

}

.disable-chip {
    pointer-events: none;
    opacity: 50%;
}


@media (pointer: fine) {
    .bankroll-chips {
        cursor: pointer;
    }
}