
.timer-main-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);

    width: 15vw;
    height: 15vw;

    max-width: 12vh;
    max-height: 12vh;

    background-color: transparent;
}

.timer {
    font-size: 4rem;
    color: black
}


.timer-numbers {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}
