

.play-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #121212;
    opacity: 80%;
    z-index: 5;
}


.play-main-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    margin: 0;
    padding: 0;

    background-color: transparent;
    z-index: 5;
}

.game-results {
    height: 15vw;
    max-height: 10vh;
    margin: 1vmin 0;
    padding: 0;
    border: none;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;

    -webkit-animation: gameResultsAnimation .25s ease-out 0s;
            animation: gameResultsAnimation .25s ease-out 0s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

}

.play-main-button {
    height: 12vw;
    max-height: 7.5vh;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;

    margin: 0 auto;
    padding: 0;
    border: none;
    background: none;
    box-sizing: border-box;


    cursor: pointer;

    -webkit-animation: playButtonAnimation .25s ease-out 0s;
            animation: playButtonAnimation .25s ease-out 0s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}



@-webkit-keyframes gameResultsAnimation {
    0% {
        transform: translateY(-150%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes gameResultsAnimation {
    0% {
        transform: translateY(-150%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}





@-webkit-keyframes playButtonAnimation {
    0% {
        transform: translateY(250%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }

}

@keyframes playButtonAnimation {
    0% {
        transform: translateY(250%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }

}


.play-button-image {
    height: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
    margin: 0;
    padding: 0;
}