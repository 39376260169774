

.mute-button {
    position: absolute;
    left: 100%;
    top: 0;
    transform: translate(-100%, 0%);

    width: 16vw;
    height: 16vw;
    
    max-width: 9dvh;
    max-height: 9dvh;

    border: none;
    background: none;
    margin: 0; 
    padding: 0;

    cursor: pointer;
    z-index: 500;
}

.mute-button-image {
    width: 100%;
    height: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
    
}