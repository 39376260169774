
/*
    MAZE
*/

.maze-main-container {
    position: absolute;
    width: 100%;
    height: 100dvh;

    margin: 0;
    padding: 0;

    background-color: rgb(0, 166, 208);

    overflow: hidden;
    touch-action: none;
}

.maze-start-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #121212;
    opacity: 80%;
    z-index: 5;
}

.maze-start-container{
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%); 

    display: flex;
    flex-direction: column;
    
    width: -webkit-max-content;
    
    width: -moz-max-content;
    
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    margin: 0;
    padding: 1vmin;

    background-color: transparent;
    z-index: 5;
}

.maze-results {
    height: 15vw;
    max-height: 10vh;
    margin: 1rem 0;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;


    border: .1vmin solid aliceblue;
    border-radius: 1vmin;

}

.maze-start-button {
    height: 12vw;
    max-height: 7.5vh;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;

    margin: auto;
    padding: 0;
    box-sizing: border-box;

    border-style: none;
    outline: .1vmin solid aliceblue;
    
    border-radius: 1vmin;
    background-color: #121212;

    cursor: pointer;

    -webkit-animation: mazeStart .25s ease-out 0s;

            animation: mazeStart .25s ease-out 0s;
}

@-webkit-keyframes mazeStart {
    0% {
        margin-top: 25vh;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
    
}

@keyframes mazeStart {
    0% {
        margin-top: 25vh;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
    
}

.maze-start-button:hover {
    background-color: skyblue;
}

.maze-play-image {
    height: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
}



/*
    MAZE BOARD
*/

.maze {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: transparent;
    /*cursor: pointer;
    */
    width: 87.5vw;
    height: 87.5vw;

    max-width: 70vh;
    max-height: 70vh;



    background-color: #12121255;

    margin: auto;
    padding: 1vmin;
    outline: .05vmin solid white;
    border-radius: 1vmin;

    touch-action: none;
    
}

.rows {
    width: 100%;
    height: 5%;
    padding: 0;
    display: flex;
}

.cell {
    background-color: #1C2833;

    width: 5%;
    height: 100%;
    margin: auto;
    padding: 0;
    display: inline-flex;

    box-sizing: border-box;

    position: relative;

    z-index: 51;

}

.exit {
    background-color: green;
}

.path {
    background-Color: aliceblue;
}



.maze-player {
    height: 100%;
    width: 100%;


    background-color: transparent;
    background-image: url('../../images/Maze/maze-player.png');
    background-size: 100%;

}


.maze-message-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-color: #1212128e;
    border-radius: inherit;
    z-index: 100;
}

.maze-message-image {
    position: absolute;
    width: 40%;
    height: 40%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2vmin;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}



