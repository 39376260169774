.App {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100dvh;

  background-color: #121212;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  overflow: hidden;

  touch-action: none;
  
}

