

.homepage-main-title {
    position: absolute;
    left: .5%;
    top: 1.5%;
    height: 8.5vw;
    max-height: 5vh;

    z-index: 100;
}

.homepage-slider-header-button {
    position: absolute;
    left: 99.5%;
    top: 1%;
    transform: translate(-100%, 0%);

    width: 9vw;
    height: 9vw;
    max-width: 6dvh;
    max-height: 6dvh;

    margin: 0;
    padding: 0;
    border: none;
    background: none;


    background-image: url('../../images/Home/slider-header-button.png');
    background-size: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    cursor: pointer;
    z-index: 100;
}


/*
    HOMEPAGE SLIDER COMPONENT
*/
.homepage-slider-main-container {
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%;
    height: 100%;

    touch-action: none;
}

.slider-buttons {
    position: absolute;
    width: 12.5vw;
    height: 12.5vw;
    max-width: 10vh;
    max-height: 10vh;

    cursor: pointer;
    z-index: 100;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
}

.slider-left {
    left: 0%;
    top: 50%;
    transform: translate(-100%, -50%);

    background-image: url('../../images/Home/slider-button-left.png');
    background-size: 100%;

    
    -webkit-animation: sliderButtonLeft .25s ease .5s;
            animation: sliderButtonLeft .25s ease .5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


@-webkit-keyframes sliderButtonLeft {
    100% {
        transform: translate(15%, -50%);    
    }
}


@keyframes sliderButtonLeft {
    100% {
        transform: translate(15%, -50%);    
    }
}

.slider-right {
    left: 100%;
    top: 50%;
    transform: translate(0%, -50%);


    background-image: url('../../images/Home/slider-button-right.png');
    background-size: 100%;

    -webkit-animation: sliderButtonRight .25s ease .5s;
            animation: sliderButtonRight .25s ease .5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes sliderButtonRight {
    100% {
        transform: translate(-115%, -50%);
    }
}

@keyframes sliderButtonRight {
    100% {
        transform: translate(-115%, -50%);
    }
}

/*
    SLIDER BACKGROUND DIVS
*/

.slider-background-card {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100vw;
    height: 100dvh;

    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.main-background-card {
    z-index: 1;
}

.slider-background-tictactoe {
    background-color: rgb(96, 205, 168);
}

.slider-background-maze {
    background-color: rgb(0, 166, 208);
}

.slider-background-4colors {
    background-color: rgb(212, 196, 175);
}

.slider-background-memorycards {
    background-color: rgb(141, 99, 180);
}

.slider-background-blackjack {
    background-color: rgb(18, 113, 18);
}


@-webkit-keyframes backgroundCardRightMount {
    0% {
        transform: translateX(100%);
    }
    
}


@keyframes backgroundCardRightMount {
    0% {
        transform: translateX(100%);
    }
    
}


@-webkit-keyframes backgroundCardLeftMount {
    0% {
        transform: translateX(-100%);
    }
}


@keyframes backgroundCardLeftMount {
    0% {
        transform: translateX(-100%);
    }
}


@-webkit-keyframes backgroundCardUnmount {
    99% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

@keyframes backgroundCardUnmount {
    99% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}


/*
    SLIDER GAME CARD
*/

.slider-game-card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    width: 70vw;
    height: 70vw;

    max-width: 45vh;
    max-height: 45vh;

    z-index: 49;
    
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.main-game-card {
    z-index: 50;
}

.game-card-title {
    position: absolute;
    left: 0;
    top: 0;

    height: 12.5%;
   
    opacity: 0;
    -webkit-animation: gameCardTitle .25s ease .30s;
            animation: gameCardTitle .25s ease .30s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


@-webkit-keyframes gameCardTitle {
    0% {
        transform: translate(80%, -110%);
    }
    100% {
        transform: translate(0%, -110%);
        opacity: 100%;
    }
    
}


@keyframes gameCardTitle {
    0% {
        transform: translate(80%, -110%);
    }
    100% {
        transform: translate(0%, -110%);
        opacity: 100%;
    }
    
}

.game-card-image {
    height: 100%;
    width: 100%;

    box-shadow: 0 0 0 .4vmin aliceblue;
    
    border-radius: 3vmin;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;

}

.desktop-only-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;

    background-color: #121212b6;

    border-radius: 3vmin;;
}

.desktop-only-image {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);

    height: 40%;
    
    -ms-interpolation-mode: nearest-neighbor;
    
        image-rendering: -moz-crisp-edges;
    
        image-rendering: pixelated;
}

.game-card-play-button {
    position: inherit;
    left: 100%; 
    top: 100%;
    transform: translate(-100%, 10%);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: 18%;
    
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;

    cursor: pointer;
}

.game-card-play-button img {
    height: 100%;
}

.desktop-only-button {
    pointer-events: none;
    opacity: 0%;
    -webkit-animation: desktopOnlyButtonAnimation .25s ease .30s;
            animation: desktopOnlyButtonAnimation .25s ease .30s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}



@-webkit-keyframes desktopOnlyButtonAnimation {
    0% {
        transform: translate(-250%, 10%);
    }
    100% {
        opacity: 50%;
    }
}



@keyframes desktopOnlyButtonAnimation {
    0% {
        transform: translate(-250%, 10%);
    }
    100% {
        opacity: 50%;
    }
}

.desktop-mobile-button {
    opacity: 0;
    -webkit-animation: gameCardPlayButton .25s ease .30s;
        animation: gameCardPlayButton .25s ease .30s;
    -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
}

@-webkit-keyframes gameCardPlayButton {
    0% {
        transform: translate(-250%, 10%);
    }
    100% {
        opacity: 100%;
    }
}

@keyframes gameCardPlayButton {
    0% {
        transform: translate(-250%, 10%);
    }
    100% {
        opacity: 100%;
    }
}





@-webkit-keyframes gameCardRightMount {
    0% {
        transform: translate(50%, -50%);

    }
    100% {
        transform: translate(-50%, -50%);
    }
}

@keyframes gameCardRightMount {
    0% {
        transform: translate(50%, -50%);

    }
    100% {
        transform: translate(-50%, -50%);
    }
}






@-webkit-keyframes gameCardRightUnmount {
    100% {
        opacity: 0%;
        transform: translate(0%, -50%);
    }
}

@keyframes gameCardRightUnmount {
    100% {
        opacity: 0%;
        transform: translate(0%, -50%);
    }
}






@-webkit-keyframes gameCardLeftMount {
    0% {
        transform: translate(-150%, -50%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}

@keyframes gameCardLeftMount {
    0% {
        transform: translate(-150%, -50%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}






@-webkit-keyframes gameCardLeftUnmount {
    100% {
        opacity: 0%;
        transform: translate(-100%, -50%);
    }
}

@keyframes gameCardLeftUnmount {
    100% {
        opacity: 0%;
        transform: translate(-100%, -50%);
    }
}













