
/*
    4 COLORS MAIN
*/

.colors-main-container {
    position: absolute;
    width: inherit;
    height: inherit;
    
    background-color: rgba(250, 235, 215, 0.635);

    touch-action: none;
}

/*
    LEVEL COMPONENT
*/

.colors-level-container {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -100%);
    
    height: 15%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    background-color: transparent;
}

.colors-level-word-image {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}

.colors-level-image {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    background-color: transparent;
}



/*
    COLOR BUTTONS
*/

.color-buttons-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 87.5vw;
    height: 87.5vw;

    max-width: 60vh;
    max-height: 60vh;

    margin: 0;
    padding: .5vmin;
}

.color-buttons {
    width: 45%;
    height: 45%;

    margin: 0;
    padding: 0;

    opacity: 45%;

    background-color: transparent;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;    
}

.color-button-red {
    background-image: url('../../images/4Colors/color-button-red.png');
    background-size: 100%;
}

.color-button-blue {
    background-image: url('../../images/4Colors/color-button-blue.png');
    background-size: 100%;
}

.color-button-green {
    background-image: url('../../images/4Colors/color-button-green.png');
    background-size: 100%;
}

.color-button-yellow {
    background-image: url('../../images/4Colors/color-button-yellow.png');
    background-size: 100%;
}

.color-clicked {
    opacity: 100%;
}



/*
    HIGHSCORE 
*/

.highscore-container-4colors {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0%);
    
    width: -webkit-max-content;
    
    width: -moz-max-content;
    
    width: max-content;
    height: 7.5%;

    display: flex;
    flex-direction: row;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

}

.highscore-image-4colors {
    height: 100%;
}

.highscore-numbers-4colors {
    height: 100%;
}



@media (pointer: fine) {
    .color-buttons {
        cursor: pointer;
    }
}
