

.memorycards-main-container {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgb(141, 99, 180);
    
    touch-action: none;
}


/*
    GAME MENU
*/

.memorycards-menu-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    display: flex;
    flex-direction: column;
    align-items: center;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    max-width: 75%;
    

}

.memorycards-menu-button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    border: none;
    background: none;
    margin: 0;
    padding: 0;

    opacity: 0;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
    cursor: pointer;
}

.memorycards-menu-button:hover {
    transform: scale(1.05);
}

.memorycards-menu-button img {
    height: 40vw;
    max-height: 25dvh;
    margin: 2.5vmin;
}

.mc-mode-16x {
    -webkit-animation: memorycardsMenuButton .5s ease .15s;
            animation: memorycardsMenuButton .5s ease .15s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.mc-mode-25x {
    -webkit-animation: memorycardsMenuButton .5s ease .25s;
            animation: memorycardsMenuButton .5s ease .25s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.mc-mode-36x {
    -webkit-animation: memorycardsMenuButton .5s ease .35s;
            animation: memorycardsMenuButton .5s ease .35s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
@-webkit-keyframes memorycardsMenuButton {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}
@keyframes memorycardsMenuButton {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}


.mc-open-menu-button {
    position: absolute;
    left: 0;
    top: 10%;

    width: 16vw;
    height: 16vw;
    max-width: 9dvh;
    max-height: 9dvh;

    box-sizing: border-box;

    border: none;
    background: none;
    background-color: blue;
    margin: 0;
    padding: 0;

    background-image: url('../../images/MemoryCards/memorycards-menu-button.png');
    background-size: 100%;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    z-index: 500;
    cursor: pointer;
}





/*
    CARDS
*/

.memory-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);


    width: 95vw;
    height: 95vw;

    max-width: 55vh;
    max-height: 55vh;

    background-color: transparent;


}

.memory-card {
    background-color: transparent;
   
    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;

    perspective: 40rem;
}

.memory-card-face {
    position: relative;
    width: 100%;
    height: 100%;

    transition: transform 0.5s;
    transform-style: preserve-3d;

    pointer-events: none;

}

.memory-card-front,
.memory-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; 
    top: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;
}

.memory-card-front {
    background-color: transparent;
}


.memory-card-back {
    font-size: 130px;
    
    background-color: transparent;
    transform: rotateY(180deg);
}


.flip-card {
    transform: rotateY(180deg);
}


.memory-cards-lives {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -125%);

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
    
    width: -webkit-max-content;
    
    
    width: -moz-max-content;
    
    
    width: max-content;        
    height: 10%;
    

}

.memory-cards-lives img {
    height: 100%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;

    margin: auto;
    padding: 0;

    opacity: 0;
    -webkit-animation: livesAnimation .25s linear;
            animation: livesAnimation .25s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    
            -webkit-animation-delay: .10s;
    
                    animation-delay: .10s;
}

@-webkit-keyframes livesAnimation {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes livesAnimation {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}


@media (pointer: fine) {
    .memory-card {
        cursor: pointer;
    }
}

@media screen and (min-width: 100dvh) {
    .memorycards-menu-container {
        flex-direction: row;
    }

    .mc-open-menu-button {
        top: 12%;

        max-width: 10vh;
        max-height: 10vh;
    }

}

