
.homepage-button {
    position: absolute;
    top: 0;
    left: 0;

    width: 16vw;
    height: 16vw;
    max-width: 9dvh;
    max-height: 9dvh;

    background-color: rgb(207, 93, 207);
    background-image: url('../images/Home/homepage-button-dark.png');
    background-size: 100%;
    background-repeat: no-repeat;

    -ms-interpolation-mode: nearest-neighbor;

        image-rendering: -moz-crisp-edges;

        image-rendering: pixelated;

    /*-webkit-animation: homepageButtonAnimation .5s ease;

            animation: homepageButtonAnimation .5s ease;
    */
    z-index: 500;
    cursor: pointer;
}

/*
.homepage-button:hover {
    background-color: #121212;
}*/

@-webkit-keyframes homepageButtonAnimation {
    0% {
        transform: translate(-100%, -100%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

@keyframes homepageButtonAnimation {
    0% {
        transform: translate(-100%, -100%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}


@media screen and (min-width: 100dvh) {
    .homepage-button {
        max-width: 10vh;
        max-height: 10vh;
    }
}